export const API_URL =
  window.location.hostname === "localhost"
    ? "https://tenexrnd.com/api"
    : `https://${window.location.hostname}/api`.replace(
        ".tenex.io",
        ".tenexrnd.com"
      );



if(!localStorage.getItem("i18nextLng")){
  var osLang = (navigator.languages
  ? navigator.languages[0]
  : (navigator.language || navigator.userLanguage));
  localStorage.setItem("i18nextLng",osLang);
  window.location.reload();
 } else {
  var osLang = localStorage.getItem("i18nextLng");
 }
 const currentLang = "lang=" + osLang;

export default {
  CITY_WEATHER:
    "http://api.openweathermap.org/data/2.5/weather?q=Dubai&appid=92207435d94b489b87943c13141fd380",
  SP: {
    IP: "https://api.ipify.org?format=json",
    BASE: API_URL,
    REGISTER_DEVICE: `${API_URL}/sp/register_device`,
    MANIFEST: `${API_URL}/sp/manifest`,
    KNOW_WHO_AM_I: `${API_URL}/sp/whoami`,
    WHITE_LABEL_CONFIGS: `${API_URL}/sp/configs?start=0&${currentLang}`,
    SERVICES_LIST: `${API_URL}/sp/services?start={{page}}&${currentLang}`,
    SERVICES_DETAIL: `${API_URL}/sp/services_detail?${currentLang}`,
    CALL_SLOTS: `${API_URL}/sp/callslots`,
    CONTACT_US: `${API_URL}/sp/contactus`,
    LOGIN: `${API_URL}/sp/login`,
    FORGOT_PASSWORD: `${API_URL}/sp/forgotpassword`,
    RESET_PASSWORD: `${API_URL}/sp/reset_password`,
    MY_SERVICES_TITLE: `${API_URL}/sp/myservices/request`,
    MY_SERVICES_CONTENT: `${API_URL}/sp/services/request`,
    MY_SERVICES_COMMENTS: `${API_URL}/sp/services/requests`,
    GET_USER_PROFILE: `${API_URL}/sp/user/profile`,
    POST_USER_PROFILE: `${API_URL}/sp/user/profile/update`,
    UPDATE_PASSWORD: `${API_URL}/sp/user/password/update`,
    GET_MY_UNITS: `${API_URL}/sp/myunits`,
    CREATE_UNIT: `${API_URL}/sp/unitonly/save`,
    UPDATE_UNIT: `${API_URL}/sp/unit/update`,
    DELETE_MY_UNIT: `${API_URL}/sp/unit/delete`,
    CHANGE_CITY: `${API_URL}/sp/setcurrentcity`,
    RESCHEDULE_CALL: `${API_URL}/sp/services/reschedulecall`,
  },
};
