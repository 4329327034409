import React from 'react'

interface Props {

}

const NotFound = (props: Props) => {
    return (
        <div>
            HELLO SP NotFound
        </div>
    )
}

export default NotFound

