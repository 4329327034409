import axios from "axios";
import moment from 'moment';
import { TCity } from "../../models/configs";
import END_POINTS from '../../api/endPoints';
import { ServicesTypes, TFreeDiagnosticCallEnable, TSelectedServiceDetailsRes, TService, TServiceProvider, TTimeSlot } from "../../models/servicesPlus/Services";

export const getServicesAndPackagesList = (currentCity: TCity, page = 0) => {
    // console.log(currentCity);
    return axios.post(END_POINTS.SP.SERVICES_LIST.replace('{{page}}', page.toString()), {
        sp_city_id: currentCity.sp_city_id,
        property_management_id: currentCity.property_management_id
    }).then(({ data, status }) => {
        localStorage.setItem("bgImg",data.message.home_config.homepage_background_image.desktop);
        const { message: { services,units,user },
            result
        } = data;
        let servicesList: TService[] = [];
        let packagesList: TService[] = [];
        services.map((service: TService) => {
            service.service_type === ServicesTypes.service ? servicesList.push(service) : packagesList.push(service)
        })
        if (status === 200 && result === 'success') {
            return {
                servicesList,
                packagesList,
                units,
                user
            }
        }
    })
}

export const getServiceDetails = (id: number, tower_id: string, property_management_id: number) => {

    return axios.post(END_POINTS.SP.SERVICES_DETAIL, {
        service_id: id,
        tower_id: tower_id,
        property_management_id:property_management_id
    })
        .then(({ data, status }: {
            data: {
                message: TSelectedServiceDetailsRes;
                result: string
            }, status: number
        }) => {
            const { message: {
                service_providers,
                freeDiagnosticCallEnable,
                currency,
                startAfter,
                timeSpan
            },
                result
            } = data
            if (status === 200 && result === 'success') {
                return {
                    currency,
                    freeDiagnosticCallEnable,
                    service_providers: service_providers.length ? service_providers : [],
                    startAfter,
                    timeSpan,
                }
            } else {
                // fire error 
                return {
                    currenc: '',
                    freeDiagnosticCallEnable: {},
                    serviceProviders: [],
                    startAfter: 0,
                    timeSpan: 0,
                }
            }
        })
}
export const getAvailableTimeSlots = (timezone: string, selectedDate: Date) => {
    var istoday = moment(selectedDate).isSame(moment(), 'day');
    var selected = moment(selectedDate).format('YYYY-MM-DD');
    
    var current_city = JSON.parse(localStorage.getItem("current_city"));
    var property_management_id = current_city.property_management_id;

    var currentTime = moment().format("HH:mm A");
    console.log(currentTime);

    return axios.post(END_POINTS.SP.CALL_SLOTS, {
        timezone,
        date: `${selected}`,
        property_management_id:property_management_id
    })
        .then(({ data, status }: {
          
            data: {
                message: {
                    slots: TTimeSlot[]
                }
                result: string
            }, status: number
        }) => {
           
            var { message: {
                slots
            },
                result
            } = data
            localStorage.setItem("tower_account_id",(slots[0])?slots[0].tower_account_id:'');
            if (status === 200 && result === 'success') {
                if(istoday){
                    return slots = slots.map((p) => {
                        var slotevalue = moment(p.value,"HH:mm A");
                        var current = moment();
                        if(slotevalue.isBefore(current)){
                            p.availability = false;
                        }
                        return p;
                    });
                } else {
                   return slots
                }
            }
            else {
                return []
            }

        })
}