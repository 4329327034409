import { combineReducers } from 'redux';
// import authReducer from './authRedcuer';
import { ServicesPlusReducer } from './ServicesPlusReducer';
import { ConfigsReducer } from './ConfigsReducer';

export default combineReducers({
    // auth: authReducer,
    servicesPlus: ServicesPlusReducer,
    config: ConfigsReducer,
});
