import { detectDeviceType } from '../utils/detectDeviceType';
import axios from './axios';
import END_POINTS from './endPoints';

// eslint-disable-next-line import/prefer-default-export
export const getConfigurations = () =>
    axios.get(END_POINTS.SP.WHITE_LABEL_CONFIGS);

export const registerDevice = () => {
    // getUserIPAddress().then(IP => {
    return axios.post(`${END_POINTS.SP.REGISTER_DEVICE}`, {
        device_type: detectDeviceType(),
        device_info: window.navigator.userAgent,
    });
    // });
};
