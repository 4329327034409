

export type TWhyUsItem = {
    icon: string;
    title: string;
    text: string;
};

export type TColor = {
    main?: string;
    text?: string;
    background?: string;
    secondary?: string;
};

export type TCity = {
    name: string;
    sp_city_id: string;
    property_management_id?: number
}

export type TLogos = {
    portrait: string;
    landscape: string;
}
export type THomepageBackgroundImage = {
    mobile: string;
    desktop: string;
    secondary_image: string;
};

export type TConfigsReducer = {
    isLoadingWhiteLabelConfigs: boolean;
    logo: TLogos;
    color: TColor;
    name: string;
    theme: string;
    title: string;
    design: string;
    slogan: string;
    page_title: string;
    initial_fee: string;
    languages: string[];
    poweredby_logo: string;
    whyUsList: TWhyUsItem[],
    diagnostic_call: boolean;
    poweredby_website: string;
    GA_tracking_id: string;
    google_captcha_key: string;
    google_map_key: string;
    homepage_background_image: THomepageBackgroundImage;

    currentCity: TCity;
    availableCites: TCity[];

    isLoadingConfigs: boolean;
    terms_conditions: string;
}
export enum ConfigsActionTypes {
    SET_IS_LOADING_CONFIGS = 'CONFIGS__SET_IS_LOADING_CONFIGS',
    SET_SELECTED_CITY_BY_ID = 'CONFIGS__SET_SELECTED_CITY_BY_ID',
    SET_SELECTED_CITY = 'CONFIGS__SET_SELECTED_CITY',
    SET_AVAILABLE_CITES = 'CONFIGS__SET_AVAILABLE_CITES',
    SET_WHITE_LABEL_CONFIGS = 'CONFIGS__SET_WHITE_LABEL_CONFIGS',

}

export type TConfigsAction = {
    type: ConfigsActionTypes;
    payload?: any;
}