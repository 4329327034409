import axios, { AxiosResponse } from "axios";
import END_POINTS from "../../api/endPoints";
import { TCity } from "../../models/configs";
import {
  TContactUs,
  TLogin,
  TForgotPassword,
  TResetPassword,
  TPostMyProfile,
  TUpdatePassword,
  TDeleteMyUnit,
  TAddUpdateUnit,
  TRescheduleCall,
} from "../../models/servicesPlus/Services";

export const knowWhoAmI: () => any = () => {
  // {
  //     current_city: TCity,
  //     cities: TCity[],
  // }
  return axios.get(END_POINTS.SP.KNOW_WHO_AM_I).then(({ data, status }) => {
    if (status === 200 && data.result === "success") {
      console.log(" data.message", data.message.current_city);
      localStorage.setItem(
        "current_city",
        JSON.stringify(data.message.current_city)
      );

      return {
        current_city: data.message.current_city,
        cities: data.message.cities,
      };
    }else{
      localStorage.setItem("token","" );
      window.location.reload();
    }
  });
};

export const getWhiteLabelConfigs = (currentCity: TCity) => {
  return axios
    .get(END_POINTS.SP.WHITE_LABEL_CONFIGS)
    .then(({ data, status }) => {
      const { message, result } = data;
      if (status === 200 && result === "success") {
        localStorage.setItem("thankMsg", message.config.thankyou);
        localStorage.setItem("errorMsg", message.config.error);
        const {
          cities,
          config: {
            logo,
            color,
            title,
            whyus,
            design,
            slogan,
            languages,
            page_title,
            initial_fee,
            poweredby_logo,
            diagnostic_call,
            poweredby_website,
            GA_tracking_id,
            google_captcha_key,
            google_map_key,
            homepage_background_image,
            terms_conditions,
          },
        } = message;

        return {
          logo,
          color,
          title,
          whyus,
          design,
          slogan,
          languages,
          page_title,
          initial_fee,
          poweredby_logo,
          diagnostic_call,
          poweredby_website,
          GA_tracking_id,
          google_map_key,
          google_captcha_key,
          availableCites: cities,
          homepage_background_image,
          terms_conditions,
        };
      }
    });
};

export const postContactUs = ({
  name,
  email,
  phoneNumber,
  message,
  sp_city_id,
}: TContactUs) => {
  return axios.post(`${END_POINTS.SP.CONTACT_US}`, {
    name,
    email,
    phoneNumber,
    message,
    sp_city_id,
  });
};

export const postLogin = ({ email, password,tower_id }: TLogin) => {
  var qs = require("qs");
  var data = qs.stringify({
    email: email,
    password: password,
    tower_id: tower_id
  });
  var config = {
    method: "post",
    url: `${END_POINTS.SP.LOGIN}`,
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
      "Content-Type": "application/x-www-form-urlencoded",
    },
    data: data,
  };

  return axios(config);
};

export const postForgotPassword = ({ email, towerId }: TForgotPassword) => {
  var qs = require("qs");
  var data = qs.stringify({
    email: email,
    tower_id: towerId,
  });
  var config = {
    method: "post",
    url: `${END_POINTS.SP.FORGOT_PASSWORD}`,
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
      "Content-Type": "application/x-www-form-urlencoded",
    },
    data: data,
  };

  return axios(config);
};

export const postResetPassword = ({ code, password }: TResetPassword) => {
  var qs = require("qs");
  var data = qs.stringify({
    code: code,
    password: password,
  });
  var config = {
    method: "post",
    url: `${END_POINTS.SP.RESET_PASSWORD}`,
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
      "Content-Type": "application/x-www-form-urlencoded",
    },
    data: data,
  };

  return axios(config);
};

export const getMyServicesTitle = () => {
  var qs = require("qs");
  var config = {
    method: "get",
    url: `${END_POINTS.SP.MY_SERVICES_TITLE}`,
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
      "Content-Type": "application/x-www-form-urlencoded",
    },
  };

  return axios(config);
};

export const getMyServicesContent = (service_request_id) => {
  var qs = require("qs");
  var config = {
    method: "get",
    url: `${END_POINTS.SP.MY_SERVICES_CONTENT}/${service_request_id}`,
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
      "Content-Type": "application/x-www-form-urlencoded",
    },
  };

  return axios(config);
};

export const postMyServicesComments = (service_request_id, text) => {
  var qs = require("qs");
  var data = qs.stringify({
    text: text,
  });
  var config = {
    method: "post",
    url: `${END_POINTS.SP.MY_SERVICES_COMMENTS}/${service_request_id}/comment`,
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
      "Content-Type": "application/x-www-form-urlencoded",
    },
    data: data,
  };

  return axios(config);
};

export const getMyProfile = () => {
  var qs = require("qs");
  var config = {
    method: "get",
    url: `${END_POINTS.SP.GET_USER_PROFILE}`,
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
      "Content-Type": "application/x-www-form-urlencoded",
    },
  };

  return axios(config);
};

export const postMyProfile = ({ name, email, mobile_no }: TPostMyProfile) => {
  var qs = require("qs");
  var data = qs.stringify({
    name: name,
    email: email,
    mobile_no: mobile_no,
  });
  var config = {
    method: "post",
    url: `${END_POINTS.SP.POST_USER_PROFILE}`,
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
      "Content-Type": "application/x-www-form-urlencoded",
    },
    data: data,
  };

  return axios(config);
};

export const updatePassword = ({ password, new_password }: TUpdatePassword) => {
  var qs = require("qs");
  var data = qs.stringify({
    password: password,
    new_password: new_password,
  });
  var config = {
    method: "post",
    url: `${END_POINTS.SP.UPDATE_PASSWORD}`,
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
      "Content-Type": "application/x-www-form-urlencoded",
    },
    data: data,
  };

  return axios(config);
};

export const getMyUnits = () => {
  var qs = require("qs");
  var config = {
    method: "get",
    url: `${END_POINTS.SP.GET_MY_UNITS}`,
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
      "Content-Type": "application/x-www-form-urlencoded",
    },
  };

  return axios(config);
};

export const addUnit = ({
  unit_area,
  unit_name,
  unit_address,
  unit_number,
  gmaps_name,
  google_place_id,
  sp_city_id,
  tower_id,
  latLng
}: TAddUpdateUnit) => {
  var qs = require("qs");
  var data = qs.stringify({
    unit_area: unit_area,
    unit_name: unit_name,
    unit_address: unit_address,
    unit_number: unit_number,
    gmaps_name: gmaps_name,
    google_place_id: google_place_id,
    sp_city_id: sp_city_id,
    tower_id: tower_id,
    latLng: latLng,
  });
  var config = {
    method: "post",
    url: `${END_POINTS.SP.CREATE_UNIT}`,
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
      "Content-Type": "application/x-www-form-urlencoded",
    },
    data: data,
  };

  return axios(config);
};

export const updateUnit = ({
  unit_id,
  unit_area,
  unit_name,
  unit_address,
  unit_number,
  gmaps_name,
  google_place_id,
  sp_city_id,
  tower_id,
  latLng
}: TAddUpdateUnit) => {
  var qs = require("qs");
  var data = qs.stringify({
    unit_id: unit_id,
    unit_area: unit_area,
    unit_name: unit_name,
    unit_address: unit_address,
    unit_number: unit_number,
    gmaps_name: gmaps_name,
    google_place_id: google_place_id,
    sp_city_id: sp_city_id,
    tower_id: tower_id,
    latLng: latLng,
  });
  var config = {
    method: "post",
    url: `${END_POINTS.SP.UPDATE_UNIT}`,
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
      "Content-Type": "application/x-www-form-urlencoded",
    },
    data: data,
  };

  return axios(config);
};

export const deleteMyUnit = ({ unit_id }: TDeleteMyUnit) => {
  var qs = require("qs");
  var data = qs.stringify({
    unit_id: unit_id,
  });
  console.log(unit_id);
  var config = {
    method: "post",
    url: `${END_POINTS.SP.DELETE_MY_UNIT}`,
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
      "Content-Type": "application/x-www-form-urlencoded",
    },
    data: data,
  };

  return axios(config);
};

export const changeCity = (sp_city_id) => {
  var qs = require("qs");
  var data = qs.stringify({
    sp_city_id: sp_city_id,
  });
  var config = {
    method: "post",
    url: `${END_POINTS.SP.CHANGE_CITY}`,
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
      "Content-Type": "application/x-www-form-urlencoded",
    },
    data: data,
  };

  return axios(config);
};

export const postRescheduleCall = ({
  service_request_id,
  property_management_id,
  diagnostic_calls_date,
  diagnostic_calls_time,
  tower_account_id,
}: TRescheduleCall) => {
  var qs = require("qs");
  var data = qs.stringify({
    service_request_id: service_request_id,
    property_management_id: property_management_id,
    diagnostic_calls_date: diagnostic_calls_date,
    diagnostic_calls_time: diagnostic_calls_time,
    tower_account_id: tower_account_id,
  });
  var config = {
    method: "post",
    url: `${END_POINTS.SP.RESCHEDULE_CALL}`,
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
      "Content-Type": "application/x-www-form-urlencoded",
    },
    data: data,
  };

  return axios(config);
};

/**
 {
    "result": "success",
    "message": {
        "config": {
            "logo"
            "color"
            "title": "Title",
            "whyus"
            "design": "design1",
            "slogan": "Slogan",
            "initial_fee": "100",
            "poweredby_logo": "https://toweron.s3-eu-west-1.amazonaws.com/1/serviceplus/design/design_1618903841.jpg",
            "diagnostic_call": true,
            "poweredby_website": "website",
            "homepage_background_image"
            "languages":
        },
    }
}
 */
