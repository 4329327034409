import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { Helmet } from "react-helmet-async";
import {
  Theme,
  makeStyles,
  createStyles,
  ThemeProvider,
  createMuiTheme,
  responsiveFontSizes,
} from "@material-ui/core/styles";

// import { lightTheme, darkTheme } from "./themes/AppTheme";
import LoadingIndicator from "./components/LoadingIndicator";
import "./App.css";
import NotFound from "./pages/NotFound";
import SP from '../src/api/endPoints';
// import { AppContextProvider, useConfigsContext } from './contexts/reducers';
import { useTranslation } from "react-i18next";
import { registerDevice } from "./api/config";
import { ConfigsActionTypes, TCity } from "./models/configs";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "./redux/store";
import {
  getWhiteLabelConfigs,
  knowWhoAmI,
} from "./redux/actions/configActions";
import { getServicesAndPackagesList } from "./redux/actions/ServicesPlusActions";
import { ServicesPlusActionTypes } from "./models/servicesPlus/Services";

const SPMain = React.lazy(() => import("./pages/servicesPlus/Main"));
const NewServiceRequestPage = React.lazy(
  () => import("./pages/servicesPlus/NewServiceRequest")
);
const ServiceRequeDetailsPage = React.lazy(
  () => import("./pages/servicesPlus/ServiceRequeDetails")
);
const MyServiceRequest = React.lazy(
  () => import("./pages/servicesPlus/MyServiceRequest")
);
const ContactUsPage = React.lazy(() => import("./pages/ContactUs"));
const LoginPage = React.lazy(() => import("./pages/Login"));
const ForgotPasswordPage = React.lazy(() => import("./pages/ForgotPassword"));
const ResetPasswordLinkPage = React.lazy(
  () => import("./pages/ResetPasswordLink")
);
const ResetPasswordPage = React.lazy(() => import("./pages/ResetPassword"));
const ThankyouPage = React.lazy(() => import("./pages/Thankyou"));
const ErrorPage = React.lazy(() => import("./pages/Error"));
const ProfilePage = React.lazy(() => import("./pages/Profile"));
const LastServicesRequestsPage = React.lazy(() => import("./pages/LastServicesRequests"));
const Paynow = React.lazy(() => import("./pages/Paynow"));

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      // backgroundColor: theme.palette.background.paper,
      backgroundColor: "#222327",
      // backgroundImage: 'linear-gradient(to bottom, rgba(255,0,0,0), rgba(255,0,0,1))',
      width: "100%",
      // height: '100vh',
    },
  })
);
function App() {
  const dispatch = useDispatch();
  const page_title = useSelector((state: AppState) => state.config.page_title);
  const GA_tracking_id = useSelector((state: AppState) => state.config.GA_tracking_id);
  // const color = useSelector((state: AppState) => state.config.color);
  // const theme = useSelector((state: AppState) => state.config.theme);
  // const { configsState, dispatch } = useConfigsContext();

  const { i18n } = useTranslation();
  // const [useDefaultTheme, toggle] = React.useReducer((theme) => !theme, true);
  const [currentTheme, setCurrentTheme] = React.useState(
    responsiveFontSizes(
      createMuiTheme({
        palette: {
          type: "dark",
          primary: {
            main: "#191A1D",
          },
          secondary: {
            main: "#FE7D55",
          },
          background: { paper: "#222327", default: "#222327" },
        },
      })
    )
  );
  const classes = useStyles();

  React.useEffect(() => {
    var token = localStorage.getItem("token");
    if (!token) {
      registerDevice().then((res) => {
        const { status, data } = res;
        if (status === 200) {
          localStorage.setItem("token", data.message.token);
          dispatchData();
        }
      });
    } else {
      dispatchData();
    }
  }, []);


  const injectGA = (trackingID) => {
    if (typeof window == 'undefined') {
      return;
    }
    window.dataLayer = window.dataLayer || [];
    function gtag() {
      window.dataLayer.push(arguments);
    }
    gtag('js', new Date());
    gtag('config', trackingID);
  };

  const dispatchData = () => {
    knowWhoAmI().then(
      ({ current_city, cities }: { current_city: TCity; cities: TCity[] }) => {
        dispatch({
          type: ConfigsActionTypes.SET_SELECTED_CITY,
          payload: current_city,
        });
        dispatch({
          type: ConfigsActionTypes.SET_AVAILABLE_CITES,
          payload: cities,
        });
        getServicesAndPackagesList(current_city).then((servicesAndpackages) => {
          dispatch({
            type: ServicesPlusActionTypes.FETCH_ALL_SERVICES_AND_PACKAGES,
            payload: servicesAndpackages,
          });
        });

        getWhiteLabelConfigs(current_city).then((configsRes) => {
          dispatch({
            type: ConfigsActionTypes.SET_WHITE_LABEL_CONFIGS,
            payload: configsRes,
          });
        });
      }
    );
  };
  // let MUtheme: Theme = theme === 'dark' ? darkTheme : lightTheme;
  // currentTheme = responsiveFontSizes(currentTheme);
  // React.useEffect(() => {
  //   setCurrentTheme(responsiveFontSizes(createMuiTheme({
  //     palette: {
  //       type: "dark",
  //       primary: {
  //         main: color.main,
  //       },
  //       secondary: {
  //         main: color.secondary,
  //       },
  //       background: {
  //         paper: color.background,
  //         default: color.background
  //       }
  //     }
  //   })
  //   ));
  //   return () => {

  //   }
  // }, [theme, color])
  return (
    <React.Suspense
      fallback={
        <div>
          <LoadingIndicator />
        </div>
      }
    >
      <ThemeProvider theme={currentTheme}>
        <Helmet htmlAttributes={{ lang: i18n.language, dir: i18n.dir() }}>
          <title>{page_title}</title>
           {<link rel="manifest" href={SP.SP.MANIFEST} />}
           {GA_tracking_id ? 
            <>
            <script async src={`https://www.googletagmanager.com/gtag/js?id=${GA_tracking_id}`}></script>
            <script>{injectGA(GA_tracking_id)}</script>
            </>
           :<></>}
        </Helmet>
        <div className={classes.root}>
          <Router>
            <Switch>
              <Route
                exact
                path={["/services", "/services/index"]}
                component={SPMain}
              />
              <Route
                exact
                path={"/services/my-requests"}
                component={MyServiceRequest}
              />
              <Route
                exact
                path={"/services/new"}
                component={NewServiceRequestPage}
              />
              {/* <Route
                exact
                path={'/services/:service_request_id'}
                component={ServiceRequeDetailsPage}
              /> */}
              <Route exact path={"/login"} component={LoginPage} />
              <Route
                exact
                path={"/forgot-password"}
                component={ForgotPasswordPage}
              />
              <Route
                exact
                path={"/reset-password-link"}
                component={ResetPasswordLinkPage}
              />
              <Route
                exact
                path={"/reset-password"}
                component={ResetPasswordPage}
              />
              <Route exact path={"/contact-us"} component={ContactUsPage} />
              <Route exact path={"/thankyou"} component={ThankyouPage} />
              <Route exact path={"/error"} component={ErrorPage} />
              <Route exact path={"/profile"} component={ProfilePage} />
              <Route exact path={"/last-services-requests"} component={LastServicesRequestsPage} />
              <Route exact path={"/paynow"} component={Paynow} />
              <Redirect from={"/"} to={"/services"} />
              <Route component={NotFound} />
            </Switch>
          </Router>
        </div>
      </ThemeProvider>
    </React.Suspense>
  );
}

export default App;
