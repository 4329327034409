import { TServicesPlusAction, TServicesPlusReducer, ServicesPlusActionTypes, STEPS } from "../../models/servicesPlus/Services";

export const ServicesPlusReducerState: TServicesPlusReducer = {
    services: [],
    packages: [],
    isLoadingServicesAndPackages: true,
    selectedService: null,
    isLoadingServices: false,
    selectedServiceProvider: {},
    isLoadingSelectedServiceProvider: false,
    ServiceProvidersList: [],
    isLoadingServiceProvidersList: false,
    isLoadingSelectedServiceResDetails: true,
    activeStep: STEPS.SERVICE_PROVIDERS,
    selectedServiceResDetails: {},
    avilableSteps: [],
    availableTimeSlot: [],
    isLoadingAvailableTimeSlot: true,
    currentStepIndex: 0,
    selectedStep:[],
    selectedAddress:[],
    finalPrice:0,
    user:[],
    units:[],
    loggedInUser:[],
    addnewproperty: false,
    error:{error:false,msg:"",button:"",path:""}
}

export const ServicesPlusReducer = (state: TServicesPlusReducer = ServicesPlusReducerState, action: TServicesPlusAction): TServicesPlusReducer => {
    switch (action.type) {

        case ServicesPlusActionTypes.SET_IS_LOADING_SERVICES_AND_PACKAGES:
            return {
                ...state,
                isLoadingServicesAndPackages: action.payload
            }
        case ServicesPlusActionTypes.FETCH_ALL_SERVICES_AND_PACKAGES:
            return {
                ...state,
                isLoadingServicesAndPackages: false,
                services: action.payload.servicesList || [],
                packages: action.payload.packagesList || [],
                units: action.payload.units || [],
                loggedInUser: action.payload.user || [],
            }

        case ServicesPlusActionTypes.SET_IS_LOADING_SELECTED_SERVICE:
            return {
                ...state,
                isLoadingServicesAndPackages: action.payload
            }
        case ServicesPlusActionTypes.SET_SELECTED_SERVICE:
            return {
                ...state,
                isLoadingServices: false,
                selectedService: action.payload
            }
        case ServicesPlusActionTypes.SET_IS_LOADING_SELECTED_SERVICE_RES_DETAILS:
            return {
                ...state,
                isLoadingSelectedServiceResDetails: true,
            }
        case ServicesPlusActionTypes.SET_SELECTED_SERVICE_RES_DETAILS:
            return {
                ...state,
                isLoadingSelectedServiceResDetails: false,
                selectedServiceResDetails: action.payload
            }

        case ServicesPlusActionTypes.SET_SERVICE_PROVIDERS_LIST:
            return {
                ...state,
                isLoadingServicesAndPackages: false,
                ServiceProvidersList: [...action.payload]
                // selectedServiceProvider: action.payload
                // ServiceProvidersList: action.payload
            }
        case ServicesPlusActionTypes.SET_SELECTED_SERVICE_PROVIDER:
            return {
                ...state,
                selectedServiceProvider: { ...action.payload },
                isLoadingSelectedServiceProvider: false,
            }
        case ServicesPlusActionTypes.SET_ACTIVE_STEP:
            return {
                ...state,
                activeStep: action.payload.activeStep,
                currentStepIndex: action.payload.currentStepIndex,
            }
        case ServicesPlusActionTypes.SET_AVILABLE_STEPS:
            return {
                ...state,
                avilableSteps: action.payload
            }

        case ServicesPlusActionTypes.SET_AVAILABLE_TIME_SLOTS:
            return {
                ...state,
                availableTimeSlot: [...action.payload],
                isLoadingAvailableTimeSlot: true,
            }

        case ServicesPlusActionTypes.SET_IS_LOADING_AVAILABLE_TIME_SLOTS:
            console.log({ NEWavailableTimeSlot: action.payload });

            return {
                ...state,
                availableTimeSlot: action.payload,
                isLoadingAvailableTimeSlot: false,
            }

        case ServicesPlusActionTypes.SERVICE_SET_DATA:
            return {
                ...state,
                selectedStep: action.payload,
            }
        case ServicesPlusActionTypes.SERVICE_SET_ADDRESS_DATA
        :
            return {
                ...state,
                selectedAddress: action.payload,
            }
        case ServicesPlusActionTypes.SERVICE_SET_FINAL_PRICE
        :
            return {
                ...state,
                finalPrice: action.payload,
            }
        case ServicesPlusActionTypes.SERVICE_SET_USER
        :
            return {
                ...state,
                user: action.payload,
                loggedInUser: action.payload,
            }
        case ServicesPlusActionTypes.SERVICE_SET_UNIT
        :
            return {
                ...state,
                units: action.payload
            }
        case ServicesPlusActionTypes.SERVICE_SET_ADD_NEW_PROPERTY
        :
            return {
                ...state,
                addnewproperty: action.payload,
            }
        case ServicesPlusActionTypes.SERVICE_SET_ERROR
        :
        var data = {error: action.payload.error, msg: action.payload.msg, button:action.payload.button,path:action.payload.path}
            return {
                ...state,
                error: data,
            }
        case ServicesPlusActionTypes.CLEAR_SELECTED_SERVICE:
            return {
                ...state,
                selectedService: null,
                isLoadingServices: false,
                selectedServiceProvider: {},
                isLoadingSelectedServiceProvider: false,
                ServiceProvidersList: [],
                isLoadingServiceProvidersList: false,
                isLoadingSelectedServiceResDetails: true,
                activeStep: STEPS.SERVICE_PROVIDERS,
                selectedServiceResDetails: {},
                avilableSteps: [],
                availableTimeSlot: [],
                isLoadingAvailableTimeSlot: true,
                currentStepIndex: 0,
                selectedStep:[],
                selectedAddress:[],
                finalPrice:0,
                addnewproperty: false,
                error:{error:false,msg:"",button:"",path:""}
                
            }

        default:
            return state;
    }
};

