import {
  TConfigsAction,
  TConfigsReducer,
  ConfigsActionTypes,
  TCity,
} from "../../models/configs";

export const ConfigsReducerState: TConfigsReducer = {
  name: "",
  theme: "dark",
  isLoadingWhiteLabelConfigs: true,
  logo: {
    portrait: "",
    landscape: "",
  },
  color: {
    main: "",
    text: "",
    background: "",
    secondary: "",
  },
  title: "",
  whyUsList: [
    {
      icon: "",
      text: "",
      title: "",
    },
  ],
  design: "",
  slogan: "",
  initial_fee: "",
  poweredby_logo: "",
  diagnostic_call: true,
  poweredby_website: "",
  homepage_background_image: {
    mobile: "",
    desktop: "",
    secondary_image: "",
  },
  languages: ["en", "ar"],
  currentCity: { name: "Cairo", sp_city_id: "C", property_management_id: 1 },
  availableCites: [
    { name: "Cairo", sp_city_id: "C", property_management_id: 1 },
  ],
  isLoadingConfigs: false,
  page_title: "",
  terms_conditions:""
};

export const ConfigsReducer = (
  state: TConfigsReducer = ConfigsReducerState,
  action: TConfigsAction
): TConfigsReducer => {
  switch (action.type) {
    case ConfigsActionTypes.SET_IS_LOADING_CONFIGS:
      return {
        ...state,
        isLoadingConfigs: action.payload,
      };
    case ConfigsActionTypes.SET_SELECTED_CITY_BY_ID:
      // console.log({ SET_SELECTED_CITY_BY_ID: action.payload });

      const selectedCity: TCity | undefined = state.availableCites.find(
        (c: TCity) => c.sp_city_id === action.payload
      );

      return {
        ...state,
        currentCity:
          selectedCity !== undefined ? selectedCity : state.currentCity,
      };

    case ConfigsActionTypes.SET_SELECTED_CITY:
      // console.log({ SET_SELECTED_CITY: action.payload });
      // const newCurrentCity = (action.payload.sp_city_id === 'GZ' || action.payload.sp_city_id === 'C') ? state.availableCites[0] : action.payload;
      return {
        ...state,
        currentCity: action.payload,
      };
    case ConfigsActionTypes.SET_AVAILABLE_CITES:
      return {
        ...state,
        availableCites: action.payload,
      };
    case ConfigsActionTypes.SET_WHITE_LABEL_CONFIGS:
      console.log(action.payload);
      console.log("action.payload");
      return {
        ...state,
        isLoadingWhiteLabelConfigs: false,
        logo: action.payload.logo ? action.payload.logo : state.logo,
        color: action.payload.color ? action.payload.color : state.color,
        title: action.payload.title ? action.payload.title : state.title,
        whyUsList: action.payload.whyus
          ? action.payload.whyus
          : state.whyUsList,
        design: action.payload.design ? action.payload.design : state.design,
        slogan: action.payload.slogan ? action.payload.slogan : state.slogan,
        initial_fee: action.payload.initial_fee
          ? action.payload.initial_fee
          : state.initial_fee,
        poweredby_logo: action.payload.poweredby_logo
          ? action.payload.poweredby_logo
          : state.poweredby_logo,
        diagnostic_call: action.payload.diagnostic_call,
        poweredby_website: action.payload.poweredby_website
          ? action.payload.poweredby_website
          : state.poweredby_website,
        GA_tracking_id: action.payload.GA_tracking_id
        ? action.payload.GA_tracking_id
        : state.GA_tracking_id,
        google_captcha_key: action.payload.google_captcha_key
        ? action.payload.google_captcha_key
        : state.google_captcha_key,
        google_map_key: action.payload.google_map_key
        ? action.payload.google_map_key
        : state.google_map_key,
        homepage_background_image: action.payload.homepage_background_image
          ? action.payload.homepage_background_image
          : state.homepage_background_image,
        languages: action.payload.languages
          ? action.payload.languages
          : state.languages,
        availableCites: action.payload.availableCites
          ? action.payload.availableCites
          : state.availableCites,
        page_title: action.payload.page_title
          ? action.payload.page_title
          : state.page_title,
        terms_conditions: action.payload.terms_conditions
      };

    default:
      return state;
  }
};
