import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import en from './en/translation.json';
import ar from './ar/translation.json';
// import { convertLanguageJsonToObject } from './translations';


export const i18n = i18next
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    react: {
      wait: true
    },
    resources: {
      en: {
        translation: en,
      },
      ar: {
        translation: ar,
      },
    },
    // fallbackLng: 'en',
    supportedLngs:['en','ar'],
    fallbackLng:['en','ar'],
    debug:
      process.env.NODE_ENV !== 'production' && process.env.NODE_ENV !== 'test',

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });
