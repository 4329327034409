export type TPackage = {
    brief: string | null; // null
    currency: string | null; // "CAD"
    discounted_from: string | null; // null
    sp_city_id: string | null; // null
    icon: string | null; // "https://toweron.s3.amazonaws.com/services/service_1608391891.png"
    id: number | null; // 123
    name: string | null; // "Intercom"
    property_management_id: number | null; // 1
    service_id: number | null; // 113
    service_type: string; // "service"
    sp_default: number | null; // 1
    starting_from: string | null; // null
    tower_id: number | null; // 473
    service_charges: number | null;
};
export enum EServiceFieldTypes {
    single_choice = "single_choice",
    photo = "photo",
    multiple_choices = "multiple_choices",
    label = "label",
    counter = "counter",
    comment = "comment",
    address = "address",
    user = "user",
    confirm = "confirm",
    units = "units",
}
export type TServiceFieldOption = {
    id: number;
    name: string;
    price: number;
    SLA: string;
};

export type TServiceField = {
    id: number;
    order_no: string;
    name: string;
    key: string;
    on: true;
    type: EServiceFieldTypes;
    required: number;
    choices?: string; //"Choice1=1=undefined\nChoice2=2=undefined\nChoice=3=undefined",
    service_field_options?: TServiceFieldOption[];
};
export type TTimeSlot = {
    value: string;
    label: string;
    availability: number;
};
export type TFreeDiagnosticCallEnable = {
    enable: boolean; // true
    header: string; // "Schedule your free diagnostic video call"
    subHeader: string; // "A Diagnostic call to help us better understand the problem, plan ahead, and send the right parts and talent so we can fix it faster."
};
export type TServiceProvider = {
    id: number; // 51,
    service_id: number; // 106,
    name: string; // "Cleaning",
    provider_name: string; // "Tenex",
    min_order: number; // 60,
    service_laundry_items: string; // [],
    working_hours_start: string; // "9",
    working_hours_end: string; // "18",
    excluded_hours: string; // "",
    is_24_hours: number; // 0,
    fields: TServiceField[]; // [],
    SLA: any; // {}
};
export type TSelectedServiceDetailsRes = {
    currency: string;
    freeDiagnosticCallEnable: TFreeDiagnosticCallEnable | {};
    service_providers: TServiceProvider[] | [];
    startAfter: number;
    timeSpan: number;
};
export type TService = {
    brief: string | null; // null
    currency: string | null; // "CAD"
    discounted_from: string | null; // null
    sp_city_id: string | null; // null
    icon: string; // "https://toweron.s3.amazonaws.com/services/service_1608391891.png"
    id: number | null; // 123
    name: string | null; // "Intercom"
    property_management_id: number | null; // 1
    service_id: number; // 113
    service_type: string; // "service"
    sp_default: number | null; // 1
    starting_from: string | null; // null
    tower_id: number | null; // 473
    service_charges: number | null; // 473
};

export enum ServicesTypes {
    service = "service",
    package = "package",
}

// Type of Actions allowed

export enum STEPS {
    SERVICE_PROVIDERS = "SERVICE_PROVIDERS",
    SINGLE_CHOICE = "SINGLE_CHOICE",
    PHOTO = "PHOTO",
    MULTIPLE_CHOICES = "MULTIPLE_CHOICES",
    LABEL = "LABEL",
    COUNTER = "COUNTER",
    COMMENT = "COMMENT",
    NO_STEPS_FOUND = "NO_STEPS_FOUND",
    CALL_TIME = "CALL_TIME",
    ADDRESS = "ADDRESS",
    USER = "USER",
    CONFIRM = "CONFIRM",
    UNITS = "UNITS",
    type = "type",
}
export enum ServicesPlusActionTypes {
    FETCH_ALL_SERVICES_AND_PACKAGES = "SERVICE__FETCH_ALL_SERVICES_AND_PACKAGES",
    FETCH_SERVICE = "SERVICE__FETCH_SERVICE",
    SET_SELECTED_SERVICE = "SERVICE__SET_SELECTED_SERVICE",
    SET_SELECTED_SERVICE_RES_DETAILS = "SERVICE__SET_SELECTED_SERVICE_RES_DETAILS",
    SET_IS_LOADING_SELECTED_SERVICE_RES_DETAILS = "SERVICE__SET_IS_LOADING_SELECTED_SERVICE_RES_DETAILS",
    SET_IS_LOADING_SELECTED_SERVICE = "SERVICE__SET_IS_LOADING_SELECTED_SERVICE",
    SET_SERVICE_PROVIDERS_LIST = "SERVICE__SET_SERVICE_PROVIDERS_LIST",
    SET_SELECTED_SERVICE_PROVIDER = "SERVICE__SET_SELECTED_SERVICE_PROVIDER",
    SET_IS_LOADING_SERVICES_AND_PACKAGES = "SERVICE__SET_IS_LOADING_SERVICES_AND_PACKAGES",
    REMOVE_SERVICE = "SERVICE__REMOVE_SERVICE",
    UPDATE_SERVICE = "SERVICE__UPDATE_SERVICE",
    SET_IS_LOADING_SERVICES = "SERVICE__SET_IS_LOADING_SERVICES",
    CLEAR_SELECTED_SERVICE = "SERVICE__CLEAR_SELECTED_SERVICE",

    SET_AVAILABLE_TIME_SLOTS = "SERVICE__SET_AVAILABLE_TIME_SLOTS",
    SET_IS_LOADING_AVAILABLE_TIME_SLOTS = "SERVICE__SET_IS_LOADING_AVAILABLE_TIME_SLOTS",

    SET_ACTIVE_STEP = "SERVICE__SET_ACTIVE_STEP",
    SET_AVILABLE_STEPS = "SERVICE__SET_AVILABLE_STEPS",

    SERVICE_SET_DATA = "SERVICE__SET_DATA",
    SERVICE_SET_ADDRESS_DATA = "SERVICE_SET_ADDRESS_DATA",
    SERVICE_SET_FINAL_PRICE = "SERVICE_SET_FINAL_PRICE",
    SERVICE_SET_USER = "SERVICE_SET_USER",
    SERVICE_SET_ADD_NEW_PROPERTY = "SERVICE_SET_ADD_NEW_PROPERTY",
    SERVICE_SET_UNIT = "SERVICE_SET_UNIT",
    SERVICE_SET_ERROR = "SERVICE_SET_ERROR"
}

export type TServicesPlusAction = {
    type: ServicesPlusActionTypes;
    payload?: any;
};

export enum EUIStep {
    id = "id",
    component = "component",
    required = "required",
    fieldObj = "fieldObj",
    name = "name",
}
export type TUIStep = {
    id: string;
    name: string;
    type: STEPS;
    component: JSX.Element;
    required: boolean;
    fieldObj: TServiceField;
};

export type TServicesPlusReducer = {
    services: TService[];
    packages: TService[];
    isLoadingServicesAndPackages: boolean;
    isLoadingServices: boolean;
    selectedServiceProvider: TServiceProvider | {};
    selectedService: TService | null;
    isLoadingSelectedServiceProvider: boolean;
    isLoadingSelectedServiceResDetails: boolean;
    ServiceProvidersList: TServiceProvider[];
    isLoadingServiceProvidersList: boolean;
    activeStep: STEPS;
    avilableSteps: TUIStep[] | [];
    selectedServiceResDetails: TSelectedServiceDetailsRes | {};
    availableTimeSlot: TTimeSlot[] | [];
    isLoadingAvailableTimeSlot: boolean;
    currentStepIndex: number;
    selectedStep: [];
    selectedAddress: [];
    finalPrice: number;
    user: [];
    units: [];
    loggedInUser: [];
    addnewproperty: boolean;
    error: {};
};
export type TContactUs = {
    name: string;
    email: string;
    phoneNumber: string;
    message: string;
    sp_city_id: string;
};

export type TLogin = {
    email: string;
    password: string;
    tower_id: string;
};

export type TForgotPassword = {
    email: string;
    towerId: string;
};

export type TResetPassword = {
    code: string;
    password: string;
};

export type TPostMyProfile = {
    name: string;
    email: string;
    mobile_no: string;
};

export type TUpdatePassword = {
    password: string;
    new_password: string;
};

export type TAddUpdateUnit = {
    unit_id: string;
    unit_area: string;
    unit_name: string;
    unit_address: string;
    unit_number: string;
    gmaps_name: string;
    google_place_id: string;
    sp_city_id: string;
    tower_id: string;
    latLng: string;
};

export type TDeleteMyUnit = {
    unit_id: string;
};

export type TRescheduleCall = {
    service_request_id: any;
    property_management_id: any;
    diagnostic_calls_date: any;
    diagnostic_calls_time: any;
    tower_account_id: any;
};

/**
    "order_no": "",
    "name": "Please select an item",
    "key": "problem",
    "on": true,
    "type": "single_choice",
    "required": 1,
    "choices": "C1==undefined\nC2==undefined\nC3==undefined",
    "id": 1,
    "service_field_options": [],

    "order_no": "",
    "name": "Please attach a photo",
    "key": "description",
    "on": true,
    "type": "photo",
    "required": 1,
    "id": 2,


 */
